.UserAccountHomePageWrapper {
  display: flex;
  width: 100%;
  max-height: 780px;

  .UserProfileInfo {
    background-color: var(--color-secondary-blue);
    border-radius: 16px;
    width: 250px;
    background-image: url('../../../../assets/images/account-profile-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 20px;

    .UserProfileContent {
      position: relative;
      padding: 30px;
    }
  }

  .UserAccountChat {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-primary-border);
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.01387);
    border-radius: 16px;
    position: relative;
    max-height: 780px;

    .UserAccountChatHeader {
      padding: 15px 30px;
      width: 100%;
      border-radius: 16px 16px 0 0;
      background-color: var(--color-primary-blue);
    }

    .UserAccountChatDiscussion {
      overflow: auto;
      flex-basis: calc(100% - 70px - 93px);

      .DiscussionContainer {
        padding: 30px 30px 0;

        .MessageItem {
          max-width: 581px;
          width: max-content;
          word-break: break-word;

          &.BotMessage {
            .Message {
              display: flex;
              align-items: center;

              img {
                margin-right: 15px;
              }
            }

            .MessageWrapper {
              background: var(--color-char-message-background);
              border-radius: 4px 16px 16px 4px;
            }
          }

          &.UserMessage {
            margin-left: auto;

            .MessageWrapper {
              background: var(--color-secondary-blue);
              border-radius: 16px 16px 4px 16px;
            }

            .MessageTime {
              margin-left: auto;
              width: max-content;
            }
          }

          .Message {
            margin-bottom: 12px;
            font-weight: 400;

            .MessageContainer {
              .MessageWrapper {
                font-size: 16px;
                line-height: 26px;
                padding: 12px 16px 12px 10px;
                margin-bottom: 5px;
              }
            }

            .MessageTime {
              font-size: 14px;
              line-height: 16px;
              color: var(--color-black);
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

.doctorStatusWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.doctorName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-primary-white);
}

.doctorStatus {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--color-primary-white);
  opacity: 0.7;
}

.userName {
  margin-top: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-modal-title);
}

.userInfoWrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.userInfoCaption,
.userInfoValue {
  font-size: 14px;
  line-height: 21px;
  color: var(--color-modal-title);
}

.userInfoCaption {
  font-weight: 400;
}

.userInfoValue {
  font-weight: 500;
}

.moreButton {
  position: absolute;
  top: 15px;
  right: 10px;
}

.userInfoList {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.UserAccountChatInput {
  width: 100%;
  padding: 18px 30px 25px 30px;
  background: var(--color-primary-white);
  border-radius: 16px;
}

.textAreaWrapper {
  position: relative;
  width: 100%;
  background: var(--color-textarea-background);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.01387);
  border-radius: 100px;
  padding: 16px 20px 9px;
}

.textArea {
  width: 95%;
  background: transparent;
  border: none;
  resize: none;
  font-weight: 400;
  font-size: 16px;
  color: var(--color-modal-title);
  padding: 0;
  line-height: 18px;
  height: 18px;

  &::placeholder {
    opacity: 0.5;
  }
}

.sendButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 300ms;

  &:hover {
    transform: translate(0, -50%) scale(1.1);
  }
}

.attachButton {
  position: absolute;
  right: 66px;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 300ms;

  &:hover {
    transform: translate(0, -50%) scale(1.1);
  }
}

.endChatWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.buttonWrapper {
  width: 65px;
}

.fileWrapper {
  max-width: 253px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.fileExtension {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-primary-white);
  background: var(--color-primary-blue);
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 58px;
    height: 58px;
    border-radius: 8px;
  }
}

.fileName {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-modal-title);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 173px;
  display: -webkit-box;
}

.fileSize {
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  color: var(--color-file-size);
}

.fileCaptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.msgWrapper {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.deleteButton {
  margin-top: 26px;
  transition: all 300ms;

  &:hover {
    transform: scale(1.1);
  }
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border-radius: 8px;
}
