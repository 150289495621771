@font-face {
  font-family: 'fontello';
  src: url('../../assets/fonts/fontello.eot?31336302');
  src: url('../../assets/fonts/fontello.eot?31336302#iefix') format('embedded-opentype'),
  url('../../assets/fonts/fontello.woff2?31336302') format('woff2'),
  url('../../assets/fonts/fontello.woff?31336302') format('woff'),
  url('../../assets/fonts/fontello.ttf?31336302') format('truetype'),
  url('../../assets/fonts/fontello.svg?31336302#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
