.wrapper {
  padding: 50px 165px 42px 155px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-white);
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--color-primary-white);
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
