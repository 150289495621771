.title {
  margin-bottom: 20px;
}

.text {
  margin-bottom: 25px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
}

.social {
  text-align: center;

  &.top-margin {
    margin-top: 10px;
  }

  .linkedin,
  .gmail {
    cursor: pointer;
  }

  .linkedin {
    margin-right: 13px;
  }
}

.form {
  width: 100%;

  button {
    display: block;
    margin: 0 auto;
  }

  .forgot {
    margin-bottom: 24px;
    margin-right: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-primary-black);
    line-height: 16px;
    border-bottom: 1px solid var(--color-primary-black);
    cursor: pointer;
  }
}

.variant {
  color: var(--color-secondary-dark-gray);
  font-size: 14px;
  line-height: 22px;

  span {
    margin-right: 8px;
  }
}

.cardText {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-secondary-dark-gray);
  max-width: 430px;
  text-align: center;

  &.bold {
    font-weight: 600;
    font-size: 16px;
    color: var(--color-primary-black);
  }
}

.cardFlexWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cardText {
    margin-bottom: 15px;
  }
}

.success {
  padding-top: 24px;
  text-align: center;
  font-weight: 600;
  color: var(--color-green-recommended-to-friends);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.inputLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-input-label);
}

.buttonWrapper {
  width: fit-content;
  margin: 0 auto;
}
