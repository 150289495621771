.wrapper {
  padding: 87px 60px;

  .TopSection {
    margin-top: 55px;
  }
}

.heading {
  font-weight: 700;
  font-size: 38px;
  line-height: 57px;
  color: var(--color-primary-white);
}

.text {
  margin-top: 20px;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-primary-white);
}

.featuresWrapper {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  row-gap: 40px;
}

.featureName {
  margin-top: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-white);
}

.featureCaption {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-white);
  opacity: 0.7;
}

.buttonWrapper {
  width: fit-content;
}
