.buttonItemWrapper {
  align-self: center;
  width: 91px;
}

.reportHeading {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: var(--color-modal-title);
}

.reportWrapper {
  width: 942px;
  padding: 29px 38px 24px 44px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.name {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--color-primary-blue);
}

.type {
  padding: 2px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: var(--color-primary-white);
  border-radius: 100px;
}

.urgent {
  background: var(--color-urgent);
}

.nonurgent {
  background: var(--color-non-urgent);
}

.reportList {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.reportItemWrapper {
  padding: 7px;
  display: flex;
  align-items: center;

  &:nth-child(odd) {
    background: var(--color-report-item-background-odd);
  }

  &:nth-child(even) {
    background: var(--color-report-item-background-even);
  }
}

.reportItemTitle,
.reportItemValue {
  font-size: 16px;
  line-height: 24px;
  color: #221F32;
}

.reportItemTitle {
  font-weight: 700;
  margin-right: 16px;
}

.reportItemValue {
  font-weight: 500;
}

.filesList {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.fileWrapper {
  padding: 8px;
  background: #A6E0E4;
  border-radius: 16px;
}
