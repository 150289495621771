.LandingPageWrapper {
  flex: 1;
  display: flex;
  height: 100%;

  .LeftPart, .RightPart {
    flex-basis: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .LeftPart {
    position: relative;
    background-color: var(--color-secondary-blue);
    background-image: url("../../assets/images/landing-left-main-bg.png");
    background-size: cover;

    .DynamicImage {
      position: absolute;
      width: 100%;
      bottom: 0;
      max-height: 88%;
      object-fit: contain;
    }
  }

  .RightPart {
    display: flex;
    justify-content: center;
    background-color: var(--color-primary-blue);
    background-image: url("../../assets/images/landing-right-main-bg.png");
    background-size: cover;
  }
}