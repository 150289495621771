.modalHeading {
  margin-bottom: 20px;
  max-width: 309px;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: var(--color-modal-title);
}

.text {
  margin-top: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-modal-title);
}

.buttonsWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.buttonItemWrapper {
  width: 91px;
}

.modalWrapper {
  width: 420px;
  padding: 35px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imagePreview {
  max-width: 580px;
  max-height: 480px;
  padding: 10px;
  border-radius: 16px;
}

.modalOverflow.modal > div {
  overflow: visible;
}
