.header {
  padding: 18px 0;
  background-color: var(--color-primary-white);
  box-shadow: 0 1px 0 #E5E9F2;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--color-primary-blue);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navWrapper ul {
  display: flex;
  gap: 32px;
}

.link, .LogOutButton {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'salt' on, 'liga' off;
  color: var(--color-black-link);
}

.activeLink {
  @extend .link;
  font-weight: 700;
  color: var(--color-primary-blue);
}

.navBlock {
  display: flex;
  align-items: center;
  gap: 32px;

  .LogOutButton {
    display: flex;
    align-items: center;
    padding: 0;

    &:before {
      content: url('../../../assets/icons/log-out.svg');
      width: 24px;
      height: 24px;
      margin-right: 10px;
      transform: rotate(180deg);
    }
  }
}

.buttonWrapper {
  width: fit-content;
}
