.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: var(--color-modal-title);
    }

    .highlightBlocksWrapper {
        max-width: 1110px;
        display: flex;
        gap: 50px;
        margin-bottom: 70px;
    }

    .universityWrappers {
        width: 100%;
        max-width: 1110px;
        margin: 0 auto 100px;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        div {
            width: 100%;
            max-width: 356px;
            height: 144px;
            background: var(--color-university-card-background);
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 8px;

            &:nth-child(1) {
                background-image: url("../../assets/images/stanford.png");
            }

            &:nth-child(2) {
                background-image: url("../../assets/images/carnegie.png");
            }

            &:nth-child(3) {
                background-image: url("../../assets/images/berkeley.png");
            }
        }
    }

    .teamWrapper {
        margin-bottom: 60px;
        width: 100%;
        background: var(--color-team-background);
        background-image: url("../../assets/images/team.jpg");
        background-repeat: no-repeat;
        background-position: right;
        background-size: 50%;
    }

    .titleWrapper {
        max-width: 1110px;
        margin-top: 60px;
        margin-bottom: 40px;
    }

    .teamTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: var(--color-input-label);
    }

    .teamText {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--color-team-text-color);
    }

    .teamContainer {
        max-width: 1110px;
        margin: 0 auto;
    }

    .teamTextWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 505px;
        padding: 50px 0;
    }

}
