.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #101010;
}

.textWrapper {
  padding-left: 20px;
  border-left: 3px solid #275E76;
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #101010;
}
