//colors
:root {
  //primary-colors
  --color-primary-white: #fff;
  --color-primary-blue: #275e76;
  --color-primary-border: #c4e0ec;

  //secondary-colors
  --color-black-link: #18191f;
  --color-secondary-blue: #a6e0e4;
  --color-black: #000;
  --color-modal-title: #221f32;
  --color-input-border: #d1d1d1;
  --color-input-background: #fbfbfb;
  --color-textarea-background: #f5f8f9;
  --color-char-message-background: #f6f8f9;
  --color-input-label: #101010;
  --color-input-placeholder: #bcbcbc;
  --color-urgent: #ed6060;
  --color-non-urgent: #1c632b;
  --color-report-item-background-odd: rgba(166, 224, 228, 0.15);
  --color-report-item-background-even: rgba(172, 188, 189, 0.15);
  --color-university-card-background: #f0f0f0;
  --color-team-background: #fbfbfb;
  --color-team-text-color: #171717;
  --color-file-size: rgba(34, 31, 50, 0.5);
}

//variables
$indent-font-size: 16px;

$MAX_LIMIT_WIDTH: '1366px';
$MAX_WIDTH_EXTRA_SMALL: '575.98px';
$MAX_WIDTH_SMALL: '767.98px';
$MAX_WIDTH_MEDIUM: '991.98px';
$MAX_WIDTH_LARGE: '1199.98px';
$MAX_WIDTH_X_LARGE: '1366.98px';

$MIN_WIDTH_EXTRA_SMALL: '576px';
$MIN_WIDTH_SMALL: '768px';
$MIN_WIDTH_MEDIUM: '992px';
$MIN_WIDTH_LARGE: '1200px';
$MIN_WIDTH_X_LARGE: '1367px';

$MAX_HEIGHT_SMALL: '830px';
