@import 'styles/main';

html {
  height: 100%;
  overflow: auto;
}

html,
body {
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.section {
  max-width: 1100px;
  margin: 0 auto;
}

input[type="password"] {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	letter-spacing: 2px;

	&::placeholder {
		font-family: "Poppins", sans-serif;
		letter-spacing: 0;
	}
}

.customSelect__control {
	background-color: var(--color-input-background) !important;
    border-color: var(--color-input-border) !important;
    border-radius: 8px !important;
    border-style: solid !important;
    border-width: 1px !important;
	box-shadow: none !important;
	cursor: pointer !important;
}

.customSelect__placeholder {
	font-size: 16px;
	color: var(--color-input-placeholder) !important;
}

.customSelect__single-value {
	font-size: 16px;
	color: var(--color-modal-title) !important;
}

.customSelect__indicator {
	padding: 15px !important;
	svg {
		fill: var(--color-input-label);
	}
}

.customSelect__indicator-separator {
  display: none;
}

.customSelect__option {
	font-size: 16px !important;
	cursor: pointer !important;
}

.customSelect__option--is-focused,
.customSelect__option--is-selected {
	color: var(--color-modal-title) !important;
	background-color: var(--color-secondary-blue) !important;
}

.customSelect__menu-list {
	cursor: pointer !important;
}
