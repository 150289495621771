@import 'src/styles/utilities/mixins';

.modal {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: none;
  background-color: rgba(36, 60, 71, 0.2);
  z-index: 99999;
  @include tablet-min() {
    background-color: transparent;
  }

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    margin-left: auto;
    width: 16px;
    height: 16px;
    background-image: url('../../../assets/icons/cross.svg');
  }

  .content {
    position: relative;
    background: #ffffff;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    overflow: hidden;

    @include tablet-min() {
      border-radius: 0;
      justify-content: flex-start;
      margin-top: auto;
      width: 100%;
      height: calc(100vh - 66px);
      padding: 75px 20px;
    }
  }
}

.modal.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
