.button {
  padding: 10px 20px;
  width: 100%;
  height: max-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-feature-settings: 'salt' on, 'liga' off;
  color: var(--color-black);
  background-color: var(--color-secondary-blue);
  border-radius: 100px;
  transition: all 300ms;

  &:hover {
    transform: scale(1.1);
  }
}
