.wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .titleWrapper {
        margin-top: 60px;

        &:after {
            content: '';
            width: 150px;
            height: 3px;
            position: relative;
            left: 50%;
            display: block;
            background: var(--color-primary-blue);
            border-radius: 100px;
            transform: translateX(-50%);
        }
    }

    .title {
        padding-bottom: 40px;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: var(--color-modal-title);
    }

    .description {
        margin: 20px auto 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
        max-width: 850px;
    }

    .descriptionText {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        color: var(--color-team-text-color);
    }

    .features {
        max-width: 1110px;
        margin: 70px auto 117px;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .featuresTitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 42px;
        text-align: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: var(--color-modal-title);
    }

    .feature {
        max-width: 582px;
        display: flex;
        gap: 40px;
    }

    .featureIcon {
        display: flex;
    }

    .featureContent {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .featureTitle {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #171717;
    }

    .featureText {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #171717;
    }

    .featuresWrapper {
        display: flex;
        align-items: center;
    }

    .phone img {
        width: 100%;
        max-width: 540px;
        height: 100%;
    }

    .featuresList {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }
}
